.background {
  background: linear-gradient(
    to bottom,
    rgb(254, 110, 110, 0.8),
    transparent 70%,
    transparent
  );
}

.separator {
  background: transparent url('/public/images/home-separator.png') repeat-x;
  animation: infinite-shift-left 500s linear infinite;
  background-size: auto 100%;
  height: 49px;
}

.separator.right {
  animation: infinite-shift-right 500s linear infinite;
}

.claim {
  @apply absolute;
  bottom: 0;
  left: 140px;
}

@media only screen and (max-width: 500px) {
  .buttons {
    @apply flex-col space-x-0 space-y-4;
  }
}

@keyframes infinite-shift-left {
  0% {
    background-position: 50000px;
  }
  100% {
    background-position: 0;
  }
}

@keyframes infinite-shift-right {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 50000px;
  }
}
