.box {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('/public/images/background.png') repeat, #4a01a8;
  background-size: 200px 200px, 100% 100%;
  @apply z-20 rounded-lg overflow-hidden max-md:!w-[90%] max-md:max-h-[90%] max-md:top-[5%] max-md:left-[5%] max-md:transform-none;
}

.alt {
  background: white;
}

.box-content {
  max-height: 80vh;
  overflow: auto;
  @apply p-10 pt-0;
}
