.label {
  @apply flex cursor-pointer text-sm font-light;
}

.label > input[type='checkbox'] {
  @apply hidden;
}

.label > input[type='checkbox'] + span {
  @apply relative border border-[#6F6F6F] rounded-[1px] mr-2 flex-shrink-0 mt-[0.2rem];
  width: 14px;
  height: 14px;
}

.label > input[type='checkbox']:checked + span::after {
  content: '';
  width: 8px;
  height: 8px;

  @apply absolute left-[2px] right-[2px] top-[2px] bottom-[2px] bg-primary-admin;
}
