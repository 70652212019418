.monthly {
  @apply px-9 py-5 mt-[-5px];
  background: url('/public/images/monthly-bg.svg') no-repeat 100% 100%;
}
.grand {
  @apply px-10 pt-9 pb-20 mt-[-5px];
  background: url('/public/images/grand-bg.svg') no-repeat 100% 100%;
}
.surprise {
  @apply px-12 pt-12 pb-9 mt-[-37px];
  background: url('/public/images/surprise-bg.svg') no-repeat 100% 100%;
}
