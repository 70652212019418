.button {
  @apply pl-6 py-[0.94rem] text-[1.75rem] leading-[32px];
}

.default {
  @apply text-white border border-white rounded-[32px];
  background-color: rgba(255, 255, 255, 0.2);
}

.default.purple {
  @apply text-white border border-white rounded-[32px];
  background-color: rgba(255, 255, 255, 0.2);
}

.gradient {
  /* background: url('/public/images/gradient-button-background.svg') no-repeat
    100% 100%; */
  @apply !border-[#F6D800];
}
