.pot,
.pot-coins {
  @apply absolute bottom-3;
  width: 280px;
  left: 50%;
  transform: translateX(-50%) scaleY(0.9);
  animation: pot-anim 3s linear infinite;
  transform-origin: bottom;
}

.shadow {
  @apply absolute bottom-1;
  width: 280px;
  left: 50%;
  transform: translateX(-50%) scaleX(1.2);
  animation: shadow-anim 3s linear infinite;
}

.rainbow {
  @apply absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation: rainbow-anim 3s linear infinite;
}

.coin {
  @apply absolute;
  width: 80px;
}

.coin-1 {
  animation: coin-1-anim 3s linear infinite;
}

.coin-2 {
  animation: coin-2-anim 3s linear infinite;
}
.coin-3 {
  animation: coin-3-anim 3s linear infinite;
}
.coin-4 {
  animation: coin-4-anim 3s linear infinite;
}
.coin-5 {
  animation: coin-5-anim 3s linear infinite;
}
.coin-6 {
  animation: coin-6-anim 3s linear infinite;
}
.coin-7 {
  animation: coin-7-anim 3s linear infinite;
}
.coin-8 {
  animation: coin-8-anim 3s linear infinite;
}

@keyframes pot-anim {
  0% {
    transform: translateX(-50%) translateY(0) scaleY(0.85);
  }
  5% {
    transform: translateX(-50%) translateY(-50px) scaleY(1.1);
  }
  10% {
    transform: translateX(-50%) translateY(-35px) scaleY(1);
  }
  15% {
    transform: translateX(-50%) translateY(-45px) scaleY(1);
  }
  20%,
  50% {
    transform: translateX(-50%) translateY(-42px) scaleY(1);
  }

  100% {
    transform: translateX(-50%) scaleY(0.85);
  }
}

@keyframes shadow-anim {
  0% {
    transform: translateX(-50%) scaleX(0.95) scaleY(1);
  }
  5% {
    transform: translateX(-50%) scaleX(0.5) scaleY(0.8);
  }
  10% {
    transform: translateX(-50%) scaleX(0.7) scaleY(0.8);
  }
  15% {
    transform: translateX(-50%) scaleX(0.65) scaleY(0.8);
  }
  20%,
  50% {
    transform: translateX(-50%) scaleX(0.68) scaleY(0.8);
  }
  100% {
    transform: translateX(-50%) scaleX(0.95) scaleY(1);
  }
}

@keyframes rainbow-anim {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  5% {
    transform: translateX(-50%) translateY(-42px);
    opacity: 1;
  }

  10% {
    transform: translateX(-50%) translateY(-27px);
    opacity: 1;
  }
  15% {
    transform: translateX(-50%) translateY(-37px);
    opacity: 1;
  }
  20%,
  50% {
    transform: translateX(-50%) translateY(-34px);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}

@keyframes coin-1-anim {
  0% {
    bottom: 150px;
    left: calc(50% + 20px);
  }
  5% {
    bottom: 520px;
    left: calc(50% + 105px);
  }
  10% {
    bottom: 410px;
    left: calc(50% + 80px);
  }
  15% {
    bottom: 440px;
    left: calc(50% + 87px);
  }
  20% {
    bottom: 410px;
    left: calc(50% + 80px);
  }
  25% {
    bottom: 415px;
    left: calc(50% + 81px);
  }
  30%,
  50% {
    bottom: 410px;
    left: calc(50% + 80px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% + 20px);
  }
}

@keyframes coin-2-anim {
  0% {
    bottom: 150px;
    left: calc(50% + 20px);
  }
  5% {
    bottom: 570px;
    left: calc(50% - 126px);
  }
  10% {
    bottom: 460px;
    left: calc(50% - 90px);
  }
  15% {
    bottom: 490px;
    left: calc(50% - 100px);
  }
  20% {
    bottom: 460px;
    left: calc(50% - 90px);
  }
  25% {
    bottom: 465px;
    left: calc(50% - 91px);
  }
  30%,
  50% {
    bottom: 460px;
    left: calc(50% - 90px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% + 20px);
  }
}

@keyframes coin-3-anim {
  0% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
  /* 110px*/
  5% {
    bottom: 440px;
    left: calc(50% + 115px);
  }
  10% {
    bottom: 330px;
    left: calc(50% + 50px);
  }
  /* 30px*/
  15% {
    bottom: 360px;
    left: calc(50% + 70px);
  }
  20% {
    bottom: 330px;
    left: calc(50% + 50px);
  }
  /* 5px*/
  25% {
    bottom: 335px;
    left: calc(50% + 53px);
  }
  30%,
  50% {
    bottom: 330px;
    left: calc(50% + 50px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
}
@keyframes coin-4-anim {
  0% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
  /* 110px*/
  5% {
    bottom: 440px;
    left: calc(50% - 100px);
  }
  10% {
    bottom: 330px;
    left: calc(50% - 100px);
  }
  /* 30px*/
  15% {
    bottom: 360px;
    left: calc(50% - 100px);
  }
  20% {
    bottom: 330px;
    left: calc(50% - 100px);
  }
  /* 5px*/
  25% {
    bottom: 335px;
    left: calc(50% - 100px);
  }
  30%,
  50% {
    bottom: 330px;
    left: calc(50% - 100px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
}

@keyframes coin-5-anim {
  0% {
    bottom: 150px;
    left: calc(50% + 10px);
  }
  /* 110px*/
  5% {
    bottom: 545px;
    left: calc(50% + 23px);
  }
  10% {
    bottom: 435px;
    left: calc(50% + 20px);
  }
  /* 30px*/
  15% {
    bottom: 465px;
    left: calc(50% + 21px);
  }
  20% {
    bottom: 435px;
    left: calc(50% + 20px);
  }
  /* 5px*/
  25% {
    bottom: 440px;
    left: calc(50% + 20px);
  }
  30%,
  50% {
    bottom: 435px;
    left: calc(50% + 20px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% + 10px);
  }
}
@keyframes coin-6-anim {
  0% {
    bottom: 150px;
    left: calc(50% + 10px);
  }
  /* 110px*/
  5% {
    bottom: 475px;
    left: calc(50% + 5px);
  }
  10% {
    bottom: 365px;
    left: calc(50%);
  }
  /* 30px*/
  15% {
    bottom: 395px;
    left: calc(50% + 1px);
  }
  20% {
    bottom: 365px;
    left: calc(50%);
  }
  /* 5px*/
  25% {
    bottom: 370px;
    left: calc(50%);
  }
  30%,
  50% {
    bottom: 365px;
    left: calc(50%);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% + 10px);
  }
}

@keyframes coin-7-anim {
  0% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
  /* 110px*/
  5% {
    bottom: 500px;
    left: calc(50% - 129px);
  }
  10% {
    bottom: 390px;
    left: calc(50% - 120px);
  }
  /* 30px*/
  15% {
    bottom: 420px;
    left: calc(50% - 123px));
  }
  20% {
    bottom: 390px;
    left: calc(50% - 120px);
  }
  /* 5px*/
  25% {
    bottom: 395px;
    left: calc(50% - 120px);
  }
  30%,
  50% {
    bottom: 390px;
    left: calc(50% - 120px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
}

@keyframes coin-8-anim {
  0% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
  /* 110px*/
  5% {
    bottom: 530px;
    left: calc(50% - 198px);
  }
  10% {
    bottom: 420px;
    left: calc(50% - 170px);
  }
  /* 30px*/
  15% {
    bottom: 450px;
    left: calc(50% - 178px));
  }
  20% {
    bottom: 420px;
    left: calc(50% - 170px);
  }
  /* 5px*/
  25% {
    bottom: 425px;
    left: calc(50% - 170px);
  }
  30%,
  50% {
    bottom: 420px;
    left: calc(50% - 170px);
  }
  70%,
  100% {
    bottom: 150px;
    left: calc(50% - 100px);
  }
}
