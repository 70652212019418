.button {
  @apply px-4 py-[0.69rem] font-medium rounded-md disabled:opacity-25 disabled:pointer-events-none;
}

.default,
.default[type='button'],
.default[type='submit'],
.default[type='reset'] {
  @apply bg-primary-admin text-white hover:bg-[#4c136e];
}

.outline,
.outline[type='button'],
.outline[type='submit'],
.outline[type='reset'] {
  @apply bg-white border border-primary-admin text-[#5F1889] hover:text-white hover:bg-primary-admin py-[0.5rem];
}

.default.small,
.default.small[type='button'],
.default.small[type='submit'],
.default.small[type='reset'] {
  @apply px-3 py-[0.4375rem];
}

.outline.small,
.outline.small[type='button'],
.outline.small[type='submit'],
.outline.small[type='reset'] {
  @apply px-3 py-1.5;
}
