.glow {
  @apply absolute left-0 right-20;
}

.back,
.middle,
.front {
  @apply absolute bottom-10 left-0;
  width: 90%;
}

.raffle {
  @apply absolute;
  width: 95px;
}

.coin {
  @apply absolute;
  width: 60px;
}

.raffle-1 {
  animation: raffle-1-anim 5s linear infinite;
}
.raffle-2 {
  animation: raffle-2-anim 5s linear infinite;
}
.raffle-3 {
  animation: raffle-3-anim 5s linear infinite;
}
.raffle-4 {
  animation: raffle-4-anim 5s linear infinite;
}

.coin-1 {
  animation: coin-1-anim 5s linear infinite;
}
.coin-2 {
  animation: coin-2-anim 5s linear infinite;
}
.coin-3 {
  animation: coin-3-anim 5s linear infinite;
}
.coin-4 {
  animation: coin-4-anim 5s linear infinite;
}
.coin-5 {
  animation: coin-5-anim 5s linear infinite;
}
.coin-6 {
  animation: coin-6-anim 5s linear infinite;
}
.coin-7 {
  animation: coin-7-anim 5s linear infinite;
}
.coin-8 {
  animation: coin-8-anim 5s linear infinite;
}
.coin-9 {
  animation: coin-9-anim 5s linear infinite;
}
.coin-10 {
  animation: coin-10-anim 5s linear infinite;
}
.coin-11 {
  animation: coin-11-anim 5s linear infinite;
}

@keyframes raffle-1-anim {
  0% {
    left: 45px;
    bottom: 110px;
  }
  50%,
  52% {
    left: 50px;
    bottom: 130px;
  }
  100% {
    left: 45px;
    bottom: 110px;
  }
}
@keyframes raffle-2-anim {
  0% {
    left: 120px;
    bottom: 150px;
  }
  50%,
  52% {
    left: 120px;
    bottom: 165px;
  }
  100% {
    left: 120px;
    bottom: 150px;
  }
}
@keyframes raffle-3-anim {
  0% {
    left: 240px;
    bottom: 115px;
  }
  50%,
  52% {
    left: 240px;
    bottom: 135px;
  }
  100% {
    left: 240px;
    bottom: 115px;
  }
}
@keyframes raffle-4-anim {
  0% {
    left: 350px;
    bottom: 135px;
  }
  50%,
  52% {
    left: 350px;
    bottom: 150px;
  }
  100% {
    left: 350px;
    bottom: 135px;
  }
}

@keyframes coin-1-anim {
  0% {
    left: 20px;
    bottom: 150px;
  }
  50%,
  52% {
    left: 20px;
    bottom: 170px;
  }
  100% {
    left: 20px;
    bottom: 150px;
  }
}

@keyframes coin-2-anim {
  0% {
    left: 35px;
    bottom: 105px;
  }
  50%,
  52% {
    left: 45px;
    bottom: 115px;
  }
  100% {
    left: 35px;
    bottom: 105px;
  }
}
@keyframes coin-3-anim {
  0% {
    left: 75px;
    bottom: 165px;
  }
  50%,
  52% {
    left: 65px;
    bottom: 170px;
  }
  100% {
    left: 75px;
    bottom: 165px;
  }
}

@keyframes coin-4-anim {
  0% {
    left: 95px;
    bottom: 115px;
  }
  50%,
  52% {
    left: 85px;
    bottom: 130px;
  }
  100% {
    left: 95px;
    bottom: 115px;
  }
}

@keyframes coin-5-anim {
  0% {
    left: 160px;
    bottom: 140px;
  }
  50%,
  52% {
    left: 170px;
    bottom: 160px;
  }
  100% {
    left: 160px;
    bottom: 140px;
  }
}
@keyframes coin-6-anim {
  0% {
    left: 170px;
    bottom: 100px;
  }
  50%,
  52% {
    left: 180px;
    bottom: 115px;
  }
  100% {
    left: 170px;
    bottom: 100px;
  }
}
@keyframes coin-7-anim {
  0% {
    left: 240px;
    bottom: 130px;
  }
  50%,
  52% {
    left: 230px;
    bottom: 140px;
  }
  100% {
    left: 240px;
    bottom: 130px;
  }
}
@keyframes coin-8-anim {
  0% {
    left: 280px;
    bottom: 95px;
  }
  50%,
  52% {
    left: 280px;
    bottom: 105px;
  }
  100% {
    left: 280px;
    bottom: 95px;
  }
}
@keyframes coin-9-anim {
  0% {
    left: 310px;
    bottom: 140px;
  }
  50%,
  52% {
    left: 300px;
    bottom: 158px;
  }
  100% {
    left: 310px;
    bottom: 140px;
  }
}
@keyframes coin-10-anim {
  0% {
    left: 340px;
    bottom: 105px;
  }
  50%,
  52% {
    left: 340px;
    bottom: 115px;
  }
  100% {
    left: 340px;
    bottom: 105px;
  }
}
@keyframes coin-11-anim {
  0% {
    left: 380px;
    bottom: 140px;
  }
  50%,
  52% {
    left: 390px;
    bottom: 155px;
  }
  100% {
    left: 380px;
    bottom: 140px;
  }
}
