@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-Dimbo text-white;
  background: url('/public/images/background.png') repeat,
    linear-gradient(#4a01a8 30%, #b67903) no-repeat;
  background-size: 100px 100px, 100% 100%;
}

#root {
  @apply relative;
}

.text-gradient {
  background: linear-gradient(to right, #ffb800 30%, #ebff00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-shadow {
  filter: drop-shadow(0 4px rgba(0, 0, 0, 0.25));
}

.separator {
  height: 21px;
  background: url('/public/images/section-separator.png') repeat;
  background-size: 45px 21px;
}

._qWTcH ._3cpN7 {
  border-radius: 4px;
  /* overflow: hidden; */
}

._qWTcH ._3cpN7 ._2EBbg:not(:last-child) {
  margin-right: -8px !important;
}
._qWTcH ._3cpN7 ._2EBbg ._SKh-V {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: #131313;
}
._qWTcH ._3cpN7 ._2EBbg ._106d2 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #131313;
  /* background-color: #2d2d2d; */
}

@media only screen and (max-width: 500px) {
  ._qWTcH {
    --fcc-digit-block-width: 30px !important;
    --fcc-digit-block-height: 106px !important;
    --fcc-digit-font-size: 28px !important;
  }
}
